import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StatusPagesComponent } from '../status-pages/status-pages.component';
import { StatusPagesService } from '../status-pages.service';
import { UUID } from 'angular2-uuid';

@Component({
  selector: 'app-state-edit',
  templateUrl: './status-edit.component.html',
  styleUrls: ['./status-edit.component.scss']
})
export class StatusEditComponent implements OnInit {
  readyToSave = false;
  statusPage;
  statusCardIndex;
  status;
  states;
  stateIndex;

  constructor(
    public dialogRef: MatDialogRef<StatusPagesComponent>,
    private statusPagesService: StatusPagesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.statusPage = this.data.statusPage;
    this.statusCardIndex = this.data.statusCardIndex;
    this.stateIndex = this.data.stateIndex;
    this.status = this.statusPage.cards[this.statusCardIndex].statuses[this.stateIndex];
    this.states = this.status.availableStates;
  }

  saveStatusPage() {
    this.statusPage.cards[this.statusCardIndex].statuses[this.stateIndex].availableStates = this.states;
    this.statusPagesService.saveStatusPage(this.statusPage).then(() => {
      this.dialogRef.close();
    });    
  }

  deleteState(stateIndex) {
    if (this.states.length > 1) {
      this.states.splice(stateIndex, 1);
    }
  }

  addState() {
    const state = {
      friendlyName: '',
      description: '',
      color: '#000000',
      text: '',
      uuid: UUID.UUID()
    }
    this.states.push(state);
    this.readyToSave = false;
  }

  stateColorChanged(event, stateIndex) {
    this.states[stateIndex].color = event.color.hex;
    this.checkSavability();
  }

  checkSavability() {
    this.readyToSave = false;
    if (this.status.name.length < 1) {
      return;
    }
    for (var i = this.states.length - 1; i >= 0; i--) {
      const state = this.states[i];
      if (state.text.length < 1 ||
        state.color.length < 1) {
        return;
      }
    };
    this.readyToSave = true;
  }
}
