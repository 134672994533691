import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServicesComponent } from '../services/services.component';
import { ServicesService } from '../services.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-state-edit',
  templateUrl: './service-log.component.html',
  styleUrls: ['./service-log.component.scss']
})

export class ServiceLogComponent implements OnInit {
  loading = true;
  commands;
  responses;
  service;

  constructor(
    public dialogRef: MatDialogRef<ServicesComponent>,
    private servicesService: ServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    if (this.data.serviceId) {
      this.servicesService.getMessages(this.data.serviceId).then((result) => {
        this.commands = result['commands'];
        this.responses = result['responses'];
        this.loading = false;
      });
    }
    if(this.data.service){
      this.service = this.data.service;
    }
  }

  getDate(month, date, year) {
    var monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return monthsArray[month - 1] + ' ' + date + ', ' + year;
  }

  getTime(timestamp) {
    var date = new Date(timestamp);
    var hours = date.getUTCHours().toString();
    var minutes = date.getUTCMinutes().toString();
    var seconds = date.getUTCSeconds().toString();

    if (hours.length < 2) {
      hours = '0' + hours;
    }
    if (minutes.length < 2) {
      minutes = '0' + minutes;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  getMessageString(message) {
    try {
      return JSON.stringify(message);
    } catch (e) {
      return message;
    }
  }

  deleteCommands(){
    this.loading = true;
    this.servicesService.deleteCommandLogs(this.data.serviceId).then(() => {
      this.commands = [];
      this.loading = false;
    });    
  }

  deleteResponses(){
    this.loading = true;
    this.servicesService.deleteResponseLogs(this.data.serviceId).then(() => {
      this.responses = [];
      this.loading = false;
    });    
  }
  
  refresh(){
    if (this.data.serviceId) {
      this.servicesService.getMessages(this.data.serviceId).then((result) => {
        this.commands = result['commands'];
        this.responses = result['responses'];
        this.loading = false;
      });
    }
  }
}