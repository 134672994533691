import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule} from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedpluginModule} from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { UnauthenticatedInterceptor } from './http-intercepter';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './auth.guard';

import { UserService } from './user.service';
import { ClientService } from './client.service';
import { TriggerService } from './trigger.service';
import { ConfigService } from './config.service';
import { ServicesService } from './services.service';
import { ContentService } from './content.service';
import { AutomationService } from './automation.service';
import { StatusPagesService } from './status-pages.service';
import { EditServiceCommandComponent } from './edit-service-command/edit-service-command.component';
import { EditServiceResponseComponent } from './edit-service-response/edit-service-response.component';
import { EditAlexaSmartHomeDeviceComponent } from './edit-alexa-smart-home-device/edit-alexa-smart-home-device.component';
import { EditClientNameComponent } from './edit-client-name/edit-client-name.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { SelectPlanComponent } from './select-plan/select-plan.component';
import { StatusEditComponent } from './status-edit/status-edit.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { ServiceLogComponent } from './service-log/service-log.component';

import { TagInputModule } from 'ngx-chips';

const PRODUCTION_CONFIG = {
  url: 'https://connect.catchtechnologies.com/',
  ws_url: 'wss://connect.catchtechnologies.com/updates'
};

// const PRODUCTION_CONFIG = {
//   url: 'https://catch-connect.herokuapp.com/',
//   ws_url: 'ws://catch-connect.herokuapp.com/updates'
// };

const DEVELOPMENT_CONFIG = {
  url: 'https://catch-connect-development.herokuapp.com/',
  ws_url: 'ws://catch-connect-development.herokuapp.com/updates'
};

const LOCAL_DEVELOPMENT_CONFIG = {
  url: 'http://localhost:3000/',
  ws_url: 'ws://localhost:3000/updates'
};

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  declarations: []
})
export class MaterialModule {}

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes),
        HttpModule,
        MaterialModule,
        MatNativeDateModule,
        MatDialogModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedpluginModule,
        HttpClientModule,
        DragDropModule,
        ColorSketchModule,
        TagInputModule, 
        ReactiveFormsModule 
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        EditServiceCommandComponent,
        EditServiceResponseComponent,
        EditAlexaSmartHomeDeviceComponent,
        EditClientNameComponent,
        SelectPlanComponent,
        StatusEditComponent,
        ServiceLogComponent
    ],
    providers: [
      UserService,
      TriggerService,
      ClientService,
      ConfigService,  
      ServicesService,  
      ContentService,
      AutomationService,
      StatusPagesService,
      AuthGuard,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: UnauthenticatedInterceptor,
        multi: true,
        deps: [Router]
      },
      { provide: 'BACKEND_CONFIG', useValue: PRODUCTION_CONFIG }
    ],
    bootstrap:    [ AppComponent ],
    entryComponents: [ EditServiceCommandComponent, EditServiceResponseComponent, EditAlexaSmartHomeDeviceComponent, EditClientNameComponent, SelectPlanComponent, StatusEditComponent, ServiceLogComponent ]
})

export class AppModule { }
