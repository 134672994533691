<mat-dialog-content>
  <div class="card">
    <div class="card-body">

      <div class="card-header row">
        <div class="col-7">
          <h4 class="card-title">{{ connection.friendlyName }} Response</h4>
          <p class="category">A message to the service</p>
        </div>
        <div class="col-md-2">
          <button mat-raised-button class="btn btn-warning btn-round" (click)="save()"><i
              class="material-icons">save_alt</i>
            Save</button>
        </div>
        <div class="col-md-2">
          <button mat-raised-button class="btn btn-primary btn-round" (click)="cancel()"><i
              class="material-icons">cancel</i>
            Cancel</button>
        </div>
        <div class="col-md-1">
          <button mat-button color="warn" (click)="delete()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Response Name</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width" placeholder="Enter a name for the response...">
            <input matInput type="text" required="true" [(ngModel)]="response.friendlyName">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Response</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width" placeholder="Enter the response...">
            <input matInput type="text" required="true" [(ngModel)]="response.pattern">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Response Format</label>
        <div class="col-sm-8">
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="format" id="format" value="string"
                (click)="setFormatToString()" [(ngModel)]="format">
              String
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="format" id="format" value="hex"
                (click)="setFormatToHex()" [(ngModel)]="format">
              Hexadecimal
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Append Special Character</label>
        <div class="col-sm-8">
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="none"
                [(ngModel)]="response.endWith">
              None
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="r"
                [(ngModel)]="response.endWith">
              \r
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="n"
                [(ngModel)]="response.endWith">
              \n
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="rn"
                [(ngModel)]="response.endWith">
              \r\n
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Tags</label>
        <tag-input [(ngModel)]='response.tags' theme='filled-theme'></tag-input>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Regular Expression</label>
        <div class="col-sm-8">
          <div class="form-check">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" [(ngModel)]="response.useRegularExpression">
              Response Includes A Regular Expression
              <span class="form-check-sign">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-4 col-form-label"></label>
        <div class="col-sm-8">
          <label>Regular expressions allow us to parse values from a response and send the result to integrations such
            as Alexa.</label>
          <label>For example, if we receive the string: <b>lightingDimValueIs77</b></label>
          <label>Enable Regular Expressions and enter: <b>^lightingDimValueIs(.+)$</b></label>
        </div>
      </div>


    </div>
  </div>
  <mat-dialog-content>