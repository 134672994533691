<mat-dialog-content>
  <div class="card">
    <div class="card-body">

      <div class="card-header row">
        <div class="col-7">
          <h4 class="card-title">{{ connection.friendlyName }} Command</h4>
          <p class="category">A message to the service</p>
        </div>
        <div class="col-md-2">
          <button mat-raised-button class="btn btn-warning btn-round" (click)="save()"><i
              class="material-icons">save_alt</i>
            Save</button>
        </div>
        <div class="col-md-2">
          <button mat-raised-button class="btn btn-primary btn-round" (click)="cancel()"><i
              class="material-icons">cancel</i>
            Cancel</button>
        </div>
        <div class="col-md-1">
          <button mat-button color="warn" (click)="delete()">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Command Name</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width" placeholder="Enter a name for the command...">
            <input matInput type="text" required="true" [(ngModel)]="command.friendlyName">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Command</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width" placeholder="Enter the command...">
            <input matInput type="text" required="true" [(ngModel)]="command.pattern">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Command Format</label>
        <div class="col-sm-8">
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="format" id="format" value="string"
                (click)="setFormatToString()" [(ngModel)]="format">
              String
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="format" id="format" value="hex"
                (click)="setFormatToHex()" [(ngModel)]="format">
              Hexadecimal
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Append Special Character</label>
        <div class="col-sm-8">
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="none"
                [(ngModel)]="command.endWith">
              None
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="r"
                [(ngModel)]="command.endWith">
              \r
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="n"
                [(ngModel)]="command.endWith">
              \n
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
          <div class="form-check form-check-radio">
            <label class="form-check-label">
              <input class="form-check-input" type="radio" name="endWith" id="endWith" value="rn"
                [(ngModel)]="command.endWith">
              \r\n
              <span class="circle">
                <span class="check"></span>
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Tags</label>
        <tag-input [(ngModel)]='command.tags' theme='filled-theme'></tag-input>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Payloads</label>
        <div class="col-sm-8">
          <p class="category"></p>
          <label>Integrations such as Alexa sometimes need to pass a payload to your command.</label>
          <label>If the command to set a lighting level to 77 is: <b>setLightingDimValueTo77</b></label>
          <label>The Command would be: <b>setLightingDimValueTo#PAYLOAD#</b></label>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Hexadecimal Commands</label>
        <div class="col-sm-8">
          <p class="category"></p>
          <label>Precede each hexadecimal value with: <b>0x</b></label>
          <label>Multiple values are separated by a comma.</label>
          <label>For example: <b>0x01,0x2B,0xFF,0x0D</b></label>
        </div>
      </div>

    </div>
  </div>
</mat-dialog-content>