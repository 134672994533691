import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Trigger } from './models/trigger';
import { ConfigService } from './config.service';

@Injectable()
export class TriggerService {
  triggers = [];

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) {}

  async getTriggers(clientId) {
    const result = await this.http.post(this.backendConfig.url + 'triggers?clientId=' + clientId, {
      token: this.configService.getToken()
    }).toPromise();
    this.triggers = <any>result;
    return this.triggers;
  }

  async addTrigger(trigger) {
    const result = await this.http.post(this.backendConfig.url + 'trigger', {
      friendlyName: trigger.friendlyName,
      searchString: trigger.searchString,
    }).toPromise();

    const newTrigger = <any>result;
    const old = this.triggers;
    this.triggers = [newTrigger].concat(this.triggers);
    if (old === this.triggers) {
      console.log('something is wrong');
    }
    return newTrigger;
  }

  async saveTrigger(trigger: Trigger) {
    const result = await this.http.put(`${this.backendConfig.url}trigger/${trigger._id}` , {
      friendlyName: trigger.friendlyName,
      searchString: trigger.searchString,
      connections: trigger.connections
    }).toPromise();

    const newTrigger = <any>result;
    const old = this.triggers;
    this.triggers = [newTrigger].concat(this.triggers);
    if (old === this.triggers) {
      console.log('something is wrong');
    }
    return newTrigger;
  }

  async delete(trigger: Trigger) {
    return this.http.post(this.backendConfig.url + 'deletetrigger', {
      id: trigger._id,
      token: this.configService.getToken()
    }).toPromise().then(() => {
      this.triggers = this.triggers.filter((a) => {
        return a._id !== trigger._id;
      });
      return Promise.resolve();
    }, () => {
      Promise.reject('err');
    });
  }
}
