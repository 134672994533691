import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class StatusPagesService {

  constructor(
    private http: HttpClient,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  async getStatusPages() {
    return this.http.get<any[]>(`${this.backendConfig.url}api/status-pages`).toPromise();
  }

  async addStatusPage(friendlyName) {
    return this.http.post(`${this.backendConfig.url}api/status-page/`, {
      friendlyName: friendlyName
    }).toPromise();
  }

  async deleteStatusPage(statusPageId) {
    return this.http.delete(this.backendConfig.url + 'api/status-page/' + statusPageId).toPromise();    
  }

  async getStatusPage(statusPageId) {
    return this.http.get(this.backendConfig.url + 'api/status-page/' + statusPageId).toPromise();
  }

  async saveStatusPage(statusPage) {
    return this.http.put(`${this.backendConfig.url}api/status-page/${statusPage._id}`, statusPage).toPromise();
  }
  
}
