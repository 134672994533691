import { Component, OnInit, Inject } from '@angular/core';
import { ServicesService } from '../services.service';
import { Response } from '../models/response'
import { ServicesComponent } from '../services/services.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-service-response',
  templateUrl: './edit-service-response.component.html',
  styleUrls: ['./edit-service-response.component.scss']
})
export class EditServiceResponseComponent implements OnInit {
  connection: any;
  response: Response;
  isNew = false;
  format = "";

  constructor(private servicesService: ServicesService,
    public dialogRef: MatDialogRef<ServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.connection = this.data.connection;
    if (this.data.response) {
      this.response = this.data.response;
    }
    this.isNew = this.data.isNew;
    if (this.response.useHex) {
      this.format = "hex";
    } else {
      this.format = "string";
    }
    if (!this.response.tags) {
      this.response.tags = [''];
      this.response.tags.splice(0, 1)
    }
  }

  setFormatToString() {
    this.response.useHex = false;
  }

  setFormatToHex() {
    this.response.useHex = true;
  }

  save() {
    this.updateTags();
    if (this.response.friendlyName != "" && this.response.pattern != "") {
      if (this.isNew) {
        this.servicesService.addResponseWithConnectionId(this.response, this.connection._id).then((newResponse) => {
          this.dialogRef.close(newResponse);
        });
      } else {
        this.servicesService.saveResponseWithConnectionId(this.response, this.connection._id).then((newResponse) => {
          this.dialogRef.close(newResponse);
        });
      }
    } else {
      alert("Please include a name and a response...");
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    this.servicesService.deleteResponse(this.response).then(() => {
      this.dialogRef.close();
    });
  }

  updateTags() {
    let newArray: [string] = [''];
    newArray.splice(0, 1);
    for (var i = 0; i < this.response.tags.length; i++) {
      var tag = this.response.tags[i];
      if (typeof (tag) === 'object') {
        newArray.push(tag['value'] as string);
      } else if (typeof (tag) === 'string') {
        newArray.push(tag);
      }
    }
    this.response.tags = newArray;
  }
}
