import { Inject, Injectable, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AutomationService {
  user;

  constructor(private http: HttpClient,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  async addAutomation(name) {
    const body = { name: name };
    return this.http.post(`${this.backendConfig.url}api/automation/`, body).toPromise();
  }

  async addAutomationWithData(body) {
    return this.http.post(`${this.backendConfig.url}api/automation-with-data/`, body).toPromise();
  }

  async getAutomations() {
    return this.http.get<any[]>(`${this.backendConfig.url}api/automations/`).toPromise();

  }

  async deleteAutomation(automationId: string) {
    return this.http.delete(this.backendConfig.url + 'api/automation/' + automationId).toPromise();
  }

  async saveAutomation(automation) {
    return this.http.put(`${this.backendConfig.url}api/automation/${automation._id}`, automation).toPromise();
  }

}
