import { Component, OnInit, Inject } from '@angular/core';
import { ClientService } from '../client.service';
import { ClientsComponent } from '../clients/clients.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-edit-client-name',
  templateUrl: './edit-client-name.component.html',
  styleUrls: ['./edit-client-name.component.scss']
})
export class EditClientNameComponent implements OnInit {
  clientName = '';
  uuid;
  saving = false;
  privacyPolicyAccepted = false;

  constructor(private clientService: ClientService,
    public dialogRef: MatDialogRef<ClientsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.uuid = this.data.clientUuid;
  }

  save() {
    if (this.clientName.length < 1) {
      alert("Please include a new name ...");
      return;
    }
    if (this.privacyPolicyAccepted == false) {
      alert("Please read and accept the Privacy Policy to continue...");
      return;
    }
    this.saving = true;
    this.clientService.claimClient(this.uuid, this.clientName).then(() => {
      this.dialogRef.close();
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
