export class AlexaSmartHomeDevice{
    _id: string;
    friendlyName: string;
    description: string;
    displayCategories: [string];
    displayCategoryFriendlyName: string;
    type: string;
    typeFriendlyName: string;
    connection: string;
    types: [any];
    
    public static TYPES = [
        {
            friendlyName: 'Light',
            name: 'light',
            controllers: ['PowerController', 'PowerLevelController', 'BrightnessController']
        }, {
            friendlyName: 'Thermostat',
            name: 'thermostat',
            controllers: ['PowerController', 'ThermostatController', 'TemperatureSensor']
        }, {
            friendlyName: 'Scene',
            name: 'scene',
            controllers: ['SceneController']
        },
        {
            friendlyName: 'Entertainment',
            name: 'entertainment',
            controllers: ['PowerController', 'ChannelController', 'InputController', 'Speaker']
        }
    ];

    public static DISPLAYCATEGORIES = [
        {
            friendlyName: 'Activity Trigger',
            name: 'ACTIVITY_TRIGGER',
        }, {
            friendlyName: 'Camera',
            name: 'CAMERA',
        }, {
            friendlyName: 'Door',
            name: 'DOOR',
        },
        {
            friendlyName: 'Light',
            name: 'LIGHT',
        },
        {
            friendlyName: 'Microwave',
            name: 'MICROWAVE',
        },
        {
            friendlyName: 'Other',
            name: 'OTHER',
        },
        {
            friendlyName: 'Scene Trigger',
            name: 'SCENE_TRIGGER',
        },
        {
            friendlyName: 'Smart Lock',
            name: 'SMARTLOCK',
        },
        {
            friendlyName: 'Smart Plug',
            name: 'SMARTPLUG',
        },
        {
            friendlyName: 'Speaker',
            name: 'SPEAKER',
        },
        {
            friendlyName: 'Switch',
            name: 'SWITCH',
        },
        {
            friendlyName: 'Temperature Sensor',
            name: 'TEMPERATURE_SENSOR',
        },
        {
            friendlyName: 'Thermostat',
            name: 'THERMOSTAT',
        },
        {
            friendlyName: 'TV',
            name: 'TV',
        }
    ];

    public static CONTROLLERS = [               //Never change the order of the controller array elements.
      {
        friendlyName: 'Power Controller',
        name: 'PowerController',
        actions: [{
            friendlyName: 'Turn On',
            name: 'turnOn',
            state: {
                name:'powerState',
                value:'ON'
            }
            }, {
            friendlyName: 'Turn Off',
            name: 'turnOff',
            state: {
                name:'powerState',
                value:'OFF'
            }
        }]
      }, {
        friendlyName: 'Power Level Controller',
        name: 'PowerLevelController',
        actions: [{
            friendlyName: 'Set Power Level',
            name: 'setPowerLevel',
            state: { 
                name:'powerLevel',
                value:null
            }
            }, {
            friendlyName: 'Adjust Power Level',
            name: 'adjustPowerLevel',
            state: {
                name:'powerLevel',
                value: null
            }
        }]
      }, {
        friendlyName: 'Brightness Controller',
        name: 'BrightnessController',
        actions: [{
            friendlyName: 'Set Brightness',
            name: 'setBrightness',
            state: {
                name:'brightness',
                value: null
            }
            }, {
            friendlyName: 'Adjust Brightness',
            name: 'adjustBrightness',
            state: {
                name:'brightness',
                value: null
            }
        }]
      }, {
        friendlyName: 'Thermostat Controller',
        name: 'ThermostatController',
          actions: [{
            friendlyName: 'Set Target Temperature',
            name: 'setTargetTemperature',
            state: {
                name:'targetSetPoint',
                value: null
            }
          },  {
            friendlyName: 'Adjust Target Temperature',
            name: 'adjustTargetTemperature',
            state: {
                name:'targetSetpoint',
                value: null
            }
          },  {
            friendlyName: 'Set Thermostat Mode',
            name: 'setThermostatMode',
            state: {
                name:'thermostatMode',
                value: null
            }
          }]
      }, {
        friendlyName: 'Channel Controller',
        name: 'ChannelController',
          actions: [{
            friendlyName: 'Change Channel',
            name: 'changeChannel',
            payload: 'channel',
            items: [{ name: '', tx: '', rx: ''}],
            state: {
                name:'channel',
                value: null
            }
          }]
      }, {
        friendlyName: 'Scene Controller',
        name: 'SceneController',
        actions: [{
            friendlyName: 'Activate',
            name: 'activate',
            state: {
                name: 'active',
                value: true
            }
          }, {
            friendlyName: 'Deactivate',
            name: 'deactivate',
            state: {
                name:'active',
                value: false
            }
          }]
      }, {
        friendlyName: 'Temperature Sensor',
        name: 'TemperatureSensor',
        actions: [{
            friendlyName: 'Report State',
            name: 'reportState',
            state: {
                name:'temperature',
                value: null
            }
          }]
      },
      {
        friendlyName: 'Input Controller',
        name: 'InputController',
        actions: [{
            friendlyName: 'selectInput',
            name: 'selectInput',
            payload: 'input',
            items: [{ name: '', tx: '', rx: ''}],
            state: {
                name:'input',
                value: null
            }
          }]
      },
      {
        friendlyName: 'Speaker',
        name: 'Speaker',
        actions: [{
            friendlyName: 'Set Volume',
            name: 'setVolume',
            state: {
                name:'volume',
                value: null
            }
          }, {
            friendlyName: 'Adjust Volume',
            name: 'adjustVolume',
            state: {
                name:'volume',
                value: null
            }
          }, {
            friendlyName: 'Set Mute',
            name: 'setMute',
            payload: 'mute',
            items: [{ name: '', tx: '', rx: ''}],
            state: {
                name: 'mute',
                value: null
            }
          }]
      }/*,
      {
        friendlyName: 'Step Speaker',
        name: 'StepSpeaker',
        actions: [{
            friendlyName: 'Adjust Volume',
            name: 'adjustVolume',
            state: {
                name:'volumeSteps',
                value: null
            }
          }, {
            friendlyName: 'Set Mute',
            name: 'setMute',
            payload: 'mute',
            items: [{ name: '', tx: '', rx: ''}],
            state: {
                name: 'mute',
                value: null
            }
          }]
      },
      {
        //https://developer.amazon.com/docs/device-apis/alexa-meetingclientcontroller.html
        friendlyName: 'Meeting Client Controller',
        name: 'MeetingClientController',
        actions: [{
            friendlyName: 'Join Meeting',
            name: 'JoinMeeting',
            state: {
                name: 'meeting',
                value: {
                    id: null,
                    pin: null,
                    endpoint: null,
                    protocol: null,
                    provider: null
                }
            }
          }, {
            friendlyName: 'End Meeting',
            name: 'EndMeeting',
            state: {
                
            }
          },{
            friendlyName: 'Join Scheduled Meeting',
            name: 'JoinScheduledMeeting',
            state: {
                name: 'calendarEventId',
                value: null
            }
        }]
      }*/
      ];   
}