import { Inject, Injectable, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { Connection } from './models/connection';

//import { ActionService } from './action.service';
//import { ConnectionService } from './connection.service';
import { TriggerService } from './trigger.service';
import { ConfigService } from './config.service';
import { ReconnectingWebSocket } from './ws/rws';
import 'rxjs/add/operator/map';

import { remove } from 'lodash';

import { MessageService } from './message.service';

@Injectable()
export class ClientService {

  user;
  socket;
  messages = [];
  devices;
  traces = {};
  clients = [];
  selectedClient;

  private _currentClient: string;
  @Input() set currentClient(value: string) {
    this._currentClient = value;
    var message = { currentClient: this._currentClient }
    this.messageService.sendMessage(message);
  }
  get currentClient(): string {
    return this._currentClient;
  }

  constructor(private http: HttpClient,
    private triggerService: TriggerService,
    private configService: ConfigService,
    private messageService: MessageService,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  clearMessage(): void {
    // clear message
    this.messageService.clearMessage();
  }

  getCurrentClient() {
    return this.getClients().map((clients) => {
      return clients.find((client) => {
        return client._id === this._currentClient;
      })
    })
  }

  getClients() {
    return this.http.post<Array<any>>(this.backendConfig.url + 'clients', {});
  }

  async updateClient(clientId, updates) {
    await this.http.put<Array<any>>(this.backendConfig.url + 'client/' + clientId, updates).toPromise();
    await this.getCurrentClient().toPromise();
    return;
  }

  async register(name: string) {
    console.log('not implemented')
  }

  sendTestString(connection, testString, useHex: boolean) {
    const data = {
      connectionId: connection,
      testString: testString,
      useHex: useHex,
      clientId: this._currentClient
    };
    this.http.post<Array<any>>(this.backendConfig.url + 'test', data).toPromise();
  }

  connect() {
    this.socket = new ReconnectingWebSocket(this.backendConfig.ws_url);
    this.socket.onopen = (event) => {
      this.socket.send(JSON.stringify(this.configService.config));
    };
    this.socket.onmessage = (event) => {
      console.log('receive a message from web socket in client service')
      if (event.data === 'Keep Alive') {
        return;
      }
      const data = JSON.parse(event.data);
    };
  }

  sendMessage(searchString: string, connectionUuid: string) {
    /**
     * First we need to check if this message actually matches a trigger that we have setup
     */
    const found = this.triggerService.triggers.find((trigger) => {
      return trigger.searchString === searchString;
    });

    if (found && this.socket) {
      const data = {
        token: this.configService.getToken(),
        trigger: found.uuid,
        connection: connectionUuid
      };
      console.log(JSON.stringify(data));
      this.socket.send(JSON.stringify(data));
    }
  }

  async loadClients() {
    this.clients = await this.http.post<Array<any>>(this.backendConfig.url + 'clients', {}).toPromise();
    return this.clients;
  }

  async loadAlexaDevices() {
    this.devices = await this.http.get(`${this.backendConfig.url}device?client=${this._currentClient}`).toPromise();
    return this.devices;
  }

  async addDevice() {
    const device = await this.http.post(this.backendConfig.url + 'device', {
      client: this._currentClient
    }).toPromise();
    this.devices.push(device);
    return device;
  }

  async claimClient(id: string, name: string) {
    const client = await this.http.post(this.backendConfig.url + 'claimClient', {
      id: id,
      name: name,
    }).toPromise();
    await this.loadClients();
  }

  async deleteClient(clientId: string) {
    await this.http.delete(this.backendConfig.url + 'client/' + clientId).toPromise();
    remove(this.clients, client => {
      return client._id === clientId;
    });
  }

  async saveDevice(device) {
    const savedDevice = await this.http.put(this.backendConfig.url + 'device/' + device._id, {
      friendlyName: device.friendlyName,
      description: device.description,
      type: device.type,
      types: device.types,
      displayCategories: device.displayCategories,
      connection: device.connection,
      client: device.client
    }).toPromise();
    this.devices = this.devices.map((d) => {
      if (d._id === savedDevice['_id']) {
        return savedDevice
      }
      return d;
    })
    return device;
  }

  async deleteDevice(device) {
    await this.http.delete(this.backendConfig.url + 'device/' + device._id).toPromise();
    remove(this.devices, (d) => {
      return d._id !== device._id;
    });
    return device;
  }

  getCurrentClientName() {
    if (!this.clients || !this._currentClient) {
      return '';
    }
    const client = this.clients.find((client) => {
      return client._id === this._currentClient;
    })
    if (client) {
      return client.friendlyName;
    }
    return '';
  }

  getCurrentClientUsers() {
    if (!this.clients || !this._currentClient) {
      return '';
    }
    const client = this.clients.find((client) => {
      return client._id === this._currentClient;
    })
    if (client) {
      return client.users;
    }
    return '';
  }

  getCurrentClientPlan() {
    if (!this.clients || !this._currentClient) {
      return null;
    }
    const client = this.clients.find((client) => {
      return client._id === this._currentClient;
    })
    if (client) {
      return client.plan;
    }
    return null;
  }

  async upgradeClientPlan(id: string) {
    await this.http.post(this.backendConfig.url + 'upgradeClient', {
      id: id,
      plan: 'Paid',
    }).toPromise();
  }

  joinBalenaCloud(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'join-balena-cloud', body).toPromise();
  }

  leaveBalenaCloud(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'leave-balena-cloud', body).toPromise();
  }

  disableBalenaUpdates(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'disable-balena-automatic-updates', body).toPromise();
  }

  enableBalenaUpdates(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'enable-balena-automatic-updates', body).toPromise();
  }

  updateBalenaDevice(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'force-balena-update', body).toPromise();
  }

  rebootBalenaDevice(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'reboot-balena-device', body).toPromise();
  }

  identifyBalenaDevice(clientUuid: string) {
    const body = { clientUuid: clientUuid }
    this.http.post(this.backendConfig.url + 'identify-balena-device', body).toPromise();
  }

  enableBalenaPublicUrl(balenaUuid: string) {
    const body = { balenaUuid: balenaUuid }
    this.http.post(this.backendConfig.url + 'enable-balena-public-url', body).toPromise();
  }

  disableBalenaPublicUrl(balenaUuid: string) {
    const body = { balenaUuid: balenaUuid }
    this.http.post(this.backendConfig.url + 'disable-balena-public-url', body).toPromise();
  }

  allowVcgControl(uuid: string, vcgUuid: string, vcgName: string) {
    const body = { vcgUuid: vcgUuid, vcgName: vcgName }
    this.http.put(this.backendConfig.url + 'allow-vcg-control/' + uuid, body).toPromise();
  }

  disallowVcgControl(uuid: string, vcgUuid: string, vcgName: string) {
    const body = { vcgUuid: vcgUuid, vcgName: vcgName }
    this.http.put(this.backendConfig.url + 'disallow-vcg-control/' + uuid, body).toPromise();
  }
}
