<mat-dialog-content>
  <div class="loader" *ngIf=loading>
    <svg class="circular" viewBox="25 25 50 50">
      <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
    </svg>
  </div>

  <h3>{{ service.friendlyName }} Commands</h3>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Date</th>
          <th># of messages</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngFor="let command of commands">
        <tr>
          <td>
            {{ getDate(command.month, command.date,  command.year) }}
          </td>
          <td>
            {{ command.nsamples }}
          </td>
          <td class="td-actions text-right">
            <button mat-raised-button class="btn btn-danger btn-just-icon btn-link" (click)="deleteCommands()">
              <i class="material-icons">delete</i>
            </button>
            <button mat-raised-button class="btn btn-default btn-just-icon btn-link" (click)="refresh()">
              <i class="material-icons">refresh</i>
            </button>
            <button *ngIf="!command.showDetail" mat-button color="default"
              (click)="command.showDetail = !command.showDetail">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button *ngIf="command.showDetail" mat-button color="default"
              (click)="command.showDetail = !command.showDetail">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </td>
        </tr>

        <tr *ngIf=command.showDetail class="hidden-table table-active">
          <td colspan="8">
            <div class="card ">
              <div class="card-body ">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Message</th>
                        <th>Origin</th>
                        <th><a href="https://en.wikipedia.org/wiki/Coordinated_Universal_Time" target="_blank">UTC</a>
                          Timestamp</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let message of command.message">
                      <tr>
                        <td>
                          {{ getMessageString(message.message) }}
                        </td>
                        <td>
                          {{ message.origin }}
                        </td>
                        <td>
                          {{ getTime(message.timestamp) }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>

    </table>
  </div>

  <h3>{{ service.friendlyName }} Responses</h3>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th>Date</th>
          <th># of messages</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngFor="let response of responses">
        <tr>
          <td>
            {{ getDate(response.month, response.date,  response.year) }}
          </td>
          <td>
            {{ response.nsamples }}
          </td>
          <td class="td-actions text-right">
            <button mat-raised-button class="btn btn-danger btn-just-icon btn-link" (click)="deleteResponses()">
              <i class="material-icons">delete</i>
            </button>
            <button mat-raised-button class="btn btn-default btn-just-icon btn-link" (click)="refresh()">
              <i class="material-icons">refresh</i>
            </button>
            <button *ngIf="!response.showDetail" mat-button color="default"
              (click)="response.showDetail = !response.showDetail">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <button *ngIf="response.showDetail" mat-button color="default"
              (click)="response.showDetail = !response.showDetail">
              <mat-icon>keyboard_arrow_down</mat-icon>
            </button>
          </td>
        </tr>

        <tr *ngIf=response.showDetail class="hidden-table table-active">
          <td colspan="8">
            <div class="card ">
              <div class="card-body ">
                <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Message</th>
                        <th>Timestamp</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let message of response.message">
                      <tr>
                        <td>
                          {{ getMessageString(message.message) }}
                        </td>
                        <td>
                          {{ getTime(message.timestamp) }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </td>
        </tr>

    </table>
  </div>
</mat-dialog-content>