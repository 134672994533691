import { Component, OnInit, Inject } from '@angular/core';
import { ServicesService } from '../services.service';
import { ClientService } from '../client.service';
import { ServicesComponent } from '../services/services.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlexaSmartHomeDevice } from '../models/alexa-smart-home-device';
import { clone } from 'lodash';

@Component({
  selector: 'app-edit-alexa-smart-home-device',
  templateUrl: './edit-alexa-smart-home-device.component.html',
  styleUrls: ['./edit-alexa-smart-home-device.component.scss']
})
export class EditAlexaSmartHomeDeviceComponent implements OnInit {

device: AlexaSmartHomeDevice;
isNew = false;   
commands;
responses;
service = {'friendlyName': ''};
services;
availableControllers: any;

constructor(private servicesService: ServicesService,
            private clientService: ClientService,
            public dialogRef: MatDialogRef<ServicesComponent>,
            @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    this.service['friendlyName'] = "";
    this.device = this.data.device;
    this.isNew = this.data.isNew;
    if(this.isNew === false) {
      this.setService(this.device.connection);
      this.setType(this.device.type);
      this.setDisplayCategoryFriendlyName();
      this.setControllersFriendlyNames();
    }      
    this.getServices(this.clientService.currentClient);
  }

  save(){
    //clear unused controller types before saving
    for(var i = this.device.types.length - 1; i >= 0 ; i--){       
      var deleteType = false;
      for(var j = this.device.types[i]['actions'].length - 1; j >= 0 ; j--){ 
        deleteType = false;
        var action = this.device.types[i].actions[j];
        if(action.command || action.response){
          break;
        }else{
          deleteType = true;
        } 
      }
      if(deleteType === true){
        this.device.types.splice(i,1);
      }
    }

    this.clientService.saveDevice(this.device).then((device) => {
      this.dialogRef.close(device)
    })
  }

  cancel() {
    if(this.isNew){
      this.clientService.deleteDevice(this.device).then(() => {
        this.dialogRef.close();
      });
    }else{
      this.dialogRef.close();
    }
  }

  async getServices(client){
    if(client){
      this.services = await this.servicesService.getConnections(client);
    }
  }

  async setService(service) {
    this.device.connection = service;
    this.service = await this.servicesService.getConnection(service);
    this.commands = await this.servicesService.getCommands(service);
    this.commands.push({friendlyName: 'No Command'});
    this.responses = await this.servicesService.getResponses(service);
    this.responses.push({friendlyName: 'No Response'});
  }

  changeService(service){
    this.clearActions();
    this.setService(service);
  }

  clearActions(){
    if(this.device.types){
      for(var i = this.device.types.length - 1; i >= 0 ; i--){ 
        for(var j = this.device.types[i]['actions'].length - 1; j >= 0 ; j--){ 
          this.device.types[i].actions[j].command = null;
          this.device.types[i].actions[j].response = null;
        }
      }
    }
  }

  getCommandFriendlyName(commandId){
    if(this.commands){
      var index = this.commands.findIndex(i => i._id == commandId);
      return this.commands[index].friendlyName;
    }else{
      return "";
    }
  }

  getResponseFriendlyName(responseId){
    if(this.responses){
      var index = this.responses.findIndex(i => i._id == responseId);
      return this.responses[index].friendlyName;
    }else{
      return "";
    }
  }

  setControllerCommand(commandId, typeIndex, actionIndex){
    if(commandId){
      this.device.types[typeIndex].actions[actionIndex].command = commandId;
    }else{
      this.device.types[typeIndex].actions[actionIndex].command = null;      
    }
  }

  setControllerResponse(responseId, typeIndex, actionIndex){
    this.device.types[typeIndex].actions[actionIndex].response = responseId;
  }

  setType(type){
    this.device.type = type;
    this.setAvailableControllers();
    this.setTypeFriendlyName();
  }
  
  changeType(type){
    this.device.types = [{}];
    this.setType(type);
  }

  setTypeFriendlyName(){
    var index = AlexaSmartHomeDevice.TYPES.findIndex(i => i.name == this.device.type);
    this.device.typeFriendlyName = AlexaSmartHomeDevice.TYPES[index].friendlyName;
  }

  setAvailableControllers(){
    if(this.device.types[0]){
      if(Object.keys(this.device.types[0]).length === 0 && this.device.types[0].constructor === Object){
        this.device.types.pop();
      }
    }
      var index = AlexaSmartHomeDevice.TYPES.findIndex(i => i.name == this.device.type);
    var controllers: any = AlexaSmartHomeDevice.TYPES[index].controllers;

    this.availableControllers = [];
    for(var i = 0; i < controllers.length; i++){  
      var controller = AlexaSmartHomeDevice.CONTROLLERS.find(j => j.name == controllers[i]);
      this.availableControllers.push(controller);
      if(this.device.types.length){
        for(var k = 0; k < this.device.types.length; k++){  
          if(this.device.types[k].name === controller.name){
            break;
          }else if(k === this.device.types.length - 1){
            this.device.types.push(controller);      
          }
        }  
      }else{
        this.device.types.push(controller);              
      }
    }
  }
  
  addController(controller){
    this.device.types.push(controller)
  }

  setDisplayCategory(displayCategories){
    this.device.displayCategories[0] = displayCategories;
    this.setDisplayCategoryFriendlyName();
  }

  setDisplayCategoryFriendlyName(){
    var index = AlexaSmartHomeDevice.DISPLAYCATEGORIES.findIndex(i => i.name == this.device.displayCategories[0]);
    this.device.displayCategoryFriendlyName = AlexaSmartHomeDevice.DISPLAYCATEGORIES[index].friendlyName;
  }

  setControllersFriendlyNames(){
    for(var i = 0; i < this.device.types.length; i++){    
      var index = AlexaSmartHomeDevice.CONTROLLERS.findIndex(k => k.name == this.device.types[i]['name']);          
      this.device.types[i]['friendlyName'] = AlexaSmartHomeDevice.CONTROLLERS[index]['friendlyName'];
      for(var j = 0; j < this.device.types[i]['actions'].length; j++){  
        this.device.types[i]['actions'][j].friendlyName = AlexaSmartHomeDevice.CONTROLLERS[index]['actions'][j]['friendlyName'];
      }
    }
  }
}
