import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { remove } from 'lodash';

@Injectable()
export class ServicesService {
  connections = [];
  serviceRoles = [];

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  async getConnections(clientId: string) {
    const result = await this.http.get(`${this.backendConfig.url}api/connection?client=${clientId}`).toPromise();
    this.connections = <any>result;
    return this.connections;
  }
  async getServiceData() {
    const result = await this.http.get(`${this.backendConfig.url}api/service-data`).toPromise();
    const serviceRoles = <any>result;
    return serviceRoles;
  }

  async getConnection(id: string) {
    return this.http.get<any>(`${this.backendConfig.url}api/connection/${id}`).toPromise();
  }

  async getCommands(connectionId: string) {
    return this.http.get(`${this.backendConfig.url}api/connection/${connectionId}/command`).toPromise();
  }

  async getResponses(connectionId: string) {
    return this.http.get(`${this.backendConfig.url}api/connection/${connectionId}/response`).toPromise();
  }

  async addCommand(command) {
    return this.http.post(`${this.backendConfig.url}api/connection/${command.connection}/command`, command).toPromise();
  }

  async addCommandWithConnectionId(command, connectionId) {
    return this.http.post(`${this.backendConfig.url}api/connection/${connectionId}/command`, command).toPromise();
  }

  async addCommandsWithConnectionId(commands, connectionId) {
    return this.http.post(`${this.backendConfig.url}api/connection/${connectionId}/commands`, commands).toPromise();
  }

  async addResponse(response) {
    return this.http.post(`${this.backendConfig.url}api/connection/${response.connection}/response`, response).toPromise();
  }

  async addResponseWithConnectionId(response, connectionId) {
    return this.http.post(`${this.backendConfig.url}api/connection/${connectionId}/response`, response).toPromise();
  }

  async addResponsesWithConnectionId(responses, connectionId) {
    return this.http.post(`${this.backendConfig.url}api/connection/${connectionId}/responses`, responses).toPromise();
  }

  async saveCommand(command) {
    return this.http.put(`${this.backendConfig.url}api/connection/${command.connection}/command/${command._id}`, command).toPromise();
  }

  async saveCommandWithConnectionId(command, connectionId) {
    return this.http.put(`${this.backendConfig.url}api/connection/${connectionId}/command/${command._id}`, command).toPromise();
  }

  async saveResponse(response) {
    return this.http.put(`${this.backendConfig.url}api/connection/${response.connection}/response/${response._id}`, response).toPromise();
  }

  async saveResponseWithConnectionId(response, connectionId) {
    return this.http.put(`${this.backendConfig.url}api/connection/${connectionId}/response/${response._id}`, response).toPromise();
  }

  async deleteCommand(command) {
    return this.http.delete(`${this.backendConfig.url}api/command/${command._id}`, command).toPromise();
  }

  async deleteCommandWithId(commandId) {
    return this.http.delete(`${this.backendConfig.url}api/command/${commandId}`).toPromise();
  }

  async deleteResponse(response) {
    return this.http.delete(`${this.backendConfig.url}api/response/${response._id}`, response).toPromise();
  }

  async deleteResponseWithId(responseId) {
    return this.http.delete(`${this.backendConfig.url}api/response/${responseId}`).toPromise();
  }

  getServiceName(serviceId) {
    var index = this.connections.findIndex(i => i._id == serviceId);
    return this.connections[index];
  }

  async addConnection(clientId: string) {
    const json = await this.http.post<any>(this.backendConfig.url + 'api/connection', {
      client: clientId
    }).toPromise();

    try {
      if (json.connection) {
        const newConnection = json.connection;
        const old = this.connections;
        this.connections = [newConnection].concat(this.connections);
        if (old === this.connections) {
          return {
            alert: {
              title: 'Something went wrong',
              text: 'Please contact support or search the support forums for more info...',
              type: 'warning',
              confirmButtonText: 'Got it!',
              timer: 5000
            }
          }
        }
        return { alert: json.alert, connection: newConnection };
      }
      return { alert: json.alert, connection: null };

    } catch (e) {
      console.log('Exception adding new service');
    }
  }

  async save(connection) {
    const result = await this.http.put(this.backendConfig.url + 'api/connection/' + connection._id, connection).toPromise();
    this.connections.map((c) => {
      if (c._id === connection._id) {
        return result;
      }
      return c;
    });
    return result;
  }

  async saveWithCommandsAndResponses(connection, commands, responses) {
    const body = {
      connection: connection,
      commands: commands,
      responses: responses
    }
    const result = await this.http.put(this.backendConfig.url + 'api/connection-with-messages/' + connection._id, body).toPromise();
    this.connections.map((c) => {
      if (c._id === connection._id) {
        return result;
      }
      return c;
    });
    return result;
  }

  async delete(connectionId) {
    await this.http.delete(this.backendConfig.url + 'api/connection/' + connectionId).toPromise();
    this.connections = remove(this.connections, (c) => {
      return c._id !== connectionId;
    });
  }

  async sendContent(connectionId: string, projectId: string) {
    const result = await this.http.post(this.backendConfig.url + 'api/start-content-download', {
      connectionId: connectionId,
      projectId: projectId
    }).toPromise();
    return;
  }

  async addServiceRole(data) {
    const result = await this.http.post(this.backendConfig.url + 'api/service-role', {
      data: data
    }).toPromise();
    return;
  }

  async getServiceRoles() {
    const result = await this.http.get(this.backendConfig.url + 'api/service-roles').toPromise();
    this.serviceRoles = <any[]>result;
    return this.serviceRoles;
  }

  async getMessages(serviceId) {
    const result = await this.http.get(this.backendConfig.url + 'api/messages/' + serviceId).toPromise();
    return result;
  }

  async deleteCommandLogs(serviceId) {
    const result = await this.http.get(this.backendConfig.url + 'api/delete-command-logs/' + serviceId).toPromise();
    return result;
  }

  async deleteResponseLogs(serviceId) {
    const result = await this.http.get(this.backendConfig.url + 'api/delete-response-logs/' + serviceId).toPromise();
    return result;
  }
}
