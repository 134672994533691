<div class="loader" *ngIf=loading>
  <svg class="circular" viewBox="25 25 50 50">
    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
  </svg>
</div>

<div class="row">
  <div class="form-check">
    <label class="form-check-label">
      <input class="form-check-input" type="checkbox" [(ngModel)]="annual" (click)="billingCycleChanged($event)">
      Select Annual Billing And Get Two Months Free
      <span class="form-check-sign">
        <span class="check"></span>
      </span>
    </label>
  </div>
</div>

<div *ngIf="clientIsManaged" class="row">
  <i class="material-icons text-danger">warning</i>
  <p class="category">The client will update and reboot after upgrading</p>
</div>

<div class="row">
  <div class="col-lg-3 col-md-6">
    <div class="card card-pricing card-plain">
      <div class="card-body">
        <h3 class="card-title">Hobby</h3>
        <div class="card-icon">
          <i class="material-icons">weekend</i>
        </div>
        <h3 class="card-title">{{ pricing[0].description }}</h3>
        <p></p>
        <p>Connect Up To 2 Local Devices And Services</p>
        <p>7 Days Data Retention for Analytics Charts</p>
        <p>Community Forums Support</p>
      </div>
      <div class="card-footer justify-content-center ">
        <a class="btn btn-white btn-round" (click)="openCheckout(pricing[0])">Select Plan</a>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-md-6">
    <div class="card card-pricing card-plain">
      <div class="card-body">
        <h3 class="card-title">DIY</h3>
        <div class="card-icon icon-primary">
          <i class="material-icons">build</i>
        </div>
        <h3 class="card-title">{{ pricing[1].description }}</h3>
        <p></p>
        <p>Connect Up To 10 Local Devices And Services</p>
        <p>30 Days Data Retention for Analytics Charts</p>
        <p>Community Forums Support</p>
      </div>
      <div class="card-footer justify-content-center ">
        <a class="btn btn-primary btn-round" (click)="openCheckout(pricing[1])">Select Plan</a>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-md-6">
    <div class="card card-pricing">
      <div class="card-body">
        <h3 class="card-title">Room Manager</h3>
        <div class="card-icon icon-warning">
          <i class="material-icons">home</i>
        </div>
        <h3 class="card-title">{{ pricing[2].description }}</h3>
        <p></p>
        <p>Connect Up To 25 Local Devices And Services</p>
        <p>90 Days Data Retention for Analytics Charts</p>
        <p>Advanced Private Support</p>
        <p>Private Status Pages</p>
      </div>
      <div class="card-footer justify-content-center ">
        <a class="btn btn-warning btn-round" (click)="openCheckout(pricing[2])">Select Plan</a>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-md-6">
    <div class="card card-pricing card-plain">
      <div class="card-body">
        <h3 class="card-title">System Manager</h3>
        <div class="card-icon icon-success">
          <i class="material-icons">domain</i>
        </div>
        <h3 class="card-title">{{ pricing[3].description }}</h3>
        <p></p>
        <p>Connect Any Number Of Local Devices And Services</p>
        <p>365 Days Retention for Analytics Charts</p>
        <p>Priority Private Support</p>
        <p>Private Status Pages</p>
      </div>
      <div class="card-footer justify-content-center ">
        <a class="btn btn-success btn-round" (click)="openCheckout(pricing[3])">Select Plan</a>
      </div>
    </div>
  </div>
</div>