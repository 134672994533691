import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserService {

  user;
  config;
  developer = false;

  constructor(
    private http: HttpClient,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) {
    // setInterval(() => {
    //   console.log('check me')
    //   if (this.isAuthenticated()) {
    //     this.me();
    //   }
    // }, 5000);
  }

  async me() {
    const result = await this.http.get(this.backendConfig.url + 'me').toPromise();
    if (result) {
      this.user = <any>result;
    } else {
      this.user = null;
    }
    this.isDeveloper(this.user);
    return this.user;
  }

  async login(username: string, password: string) {
    const result = await this.http.post(this.backendConfig.url + 'login', {
      username: username,
      password: password
    }).toPromise();
    if (result) {
      this.user = <any>result;
    } else {
      this.user = null;
    }
    this.isDeveloper(this.user);
    return this.user;
  }

  async signup(username: string, password: string) {
    return this.http.post(this.backendConfig.url + 'signup', {
      username: username,
      password: password
    }).toPromise();
  }

  async completeSignup(username: string, password: string, token: string) {
    const result = await this.http.post(this.backendConfig.url + 'completesignup', {
      username: username,
      password: password,
      token: token
    }).toPromise();    
    this.user = <any>result;    
    this.isDeveloper(this.user);
    return this.user;
  }

  async logout() {
    this.user = null;
    const result = await this.http.post(this.backendConfig.url + 'logout', {}).toPromise();
    this.isDeveloper(this.user);
    return result;
  }

  //
  // async getMyDevices() {
  //   const result = await this.http.get(this.backendConfig.url + 'devices').toPromise();
  //   return <any>result;
  // }
  //
  // async addDevice(name: string, port: number) {
  //   const result = await this.http.put(this.backendConfig.url + 'device', {
  //     name: name,
  //     port: port
  //   }).toPromise();
  //   return <any>result;
  // }

  isAuthenticated() {
    return !!this.user;
  }

  initPasswordReset(username: string) {
    return this.http.post(this.backendConfig.url + 'initreset', {
      username: username
    }).toPromise();
  }

  async completePasswordReset(username: string, password: string, token: string) {
    const result = await this.http.post(this.backendConfig.url + 'completereset', {
      username: username,
      password: password,
      token: token
    }).toPromise();
    this.user = <any>result;
    this.isDeveloper(this.user);
    return this.user;
  }

  isDeveloper(user) {
    if (user) {
      if (user.developer) {
        this.developer = user.developer;
      } else {
        this.developer = false;
      }
    }
  }
}
