<mat-dialog-content>
  <div class="card-header">
    <div class="row">
      <div class="col-md-9">
        <h4 class="card-title"> Status Page: {{ statusPage.friendlyName }} </h4>
      </div>
      <div class="col-md-2">
        <button [disabled]="!readyToSave" mat-raised-button class="btn btn-warning btn-round"
          (click)="saveStatusPage()"><i class="material-icons">save_alt</i> Save</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <h4 class="card-title"> Status Name: </h4>
      </div>
      <div class="col-md-8">
        <input required [(ngModel)]="status.name" (ngModelChange)="checkSavability()" type="text" value=""
          placeholder="Enter a name for this status, like Room 1." class="form-control" />
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>State</th>
          <th>Color</th>
          <th>
            <button mat-raised-button class="btn btn-default btn-just-icon btn-round pull-right" (click)="addState()">
              <i class="material-icons">add</i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let state of states; index as i">
          <td>
            <input required [(ngModel)]="state.text" (ngModelChange)="checkSavability()" type="text" value=""
              placeholder="The state text." class="form-control" />
          </td>
          <td>
            <div class="row">
              <div class="col-sm-7">
                <input required [style.color]="state.color" [(ngModel)]="state.color"
                  (ngModelChange)="checkSavability()" type="text" value="" placeholder="The state color."
                  class="form-control" />
              </div>


              <div class="col-sm-1">
                <button (click)="state.colorActive = !state.colorActive" class="btn dropdown-toggle btn-sm">
                  <b class="caret"></b>
                </button>
              </div>
              <color-sketch *ngIf="state.colorActive" [color]="state.color"
                (onChangeComplete)="stateColorChanged($event, i)"></color-sketch>

            </div>
          </td>
          <td class="td-actions text-right">
            <button mat-raised-button type="button" class="btn btn-danger btn-link" (click)="deleteState(i)">
              <i class="material-icons">delete</i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>