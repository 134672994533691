<div class="fixed-plugin">
    <div class="dropdown show-dropdown">
        <a href="#" data-toggle="dropdown" aria-expanded="true">
            <i class="fa fa-cog fa-2x"> </i>
        </a>
        <ul class="dropdown-menu">    
            <div class="row" *ngIf="clientService.clients; let clients">
                <li class="header-title"> Clients</li>
                <div class="table-responsive">
                    <table class="table">                        
                        <tbody>
                            <tr *ngFor="let client of clients">
                                <td (click)="selectClient(client._id)">{{client.friendlyName}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <li class="adjustments-line">
                <a href="javascript:void(0)" class="switch-trigger background-color">
                    <p>Sidebar Background</p>
                    <div class="ml-auto mr-auto">
                        <span class="badge filter badge-white" data-color="white"></span>
                        <span class="badge filter badge-black" data-color="black"></span>
                    </div>
                <div class="clearfix"></div>
                </a>
            </li>
            
            <li class="adjustments-line">
              <a href="javascript:void(0)" class="switch-trigger">
                  <p>Sidebar Minimize</p>
                  <label class="ml-auto">
                    <div class="togglebutton switch-sidebar-mini">
                      <label>
                          <input type="checkbox">
                              <span class="toggle"></span>
                      </label>
                    </div>
                  </label>
                  <div class="clearfix"></div>
                  <div class="ripple-container"></div>
              </a>
            </li>            
        </ul>
    </div>
</div>
