<mat-dialog-content>
  
  <div class="card">                                
    <div class="card-body">
        
      <div class="card-header row">
        <div class="col-6">
          <h4 class="card-title">{{ device.friendlyName }} Properties</h4>
          <p class="category"></p>
        </div>
        <div class="col-md-3">
          <button [disabled]="!device.displayCategoryFriendlyName || !device.type || !service.friendlyName" mat-raised-button class="btn btn-warning btn-round" (click)="save()"><i class="material-icons">save_alt</i>  Save</button>
        </div>
        <div class="col-md-3">
            <button mat-raised-button class="btn btn-primary btn-round" (click)="cancel()"><i class="material-icons">cancel</i> Cancel</button>
        </div>
      </div>                           
        
      <div class="row">
        <label class="col-sm-4 col-form-label">Device Name</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width"  placeholder="Enter a name for the command...">
            <input matInput type="text" required="true" [(ngModel)]="device.friendlyName">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Description</label>
        <div class="col-sm-8">
          <mat-form-field class="example-full-width"  placeholder="A description for the Alexa App...">
            <input matInput type="text" [(ngModel)]="device.description">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <label class="col-sm-4 col-form-label">Display Category</label>
        <div class="col-sm-6">
          <mat-form-field class="example-full-width"  placeholder="Select a category for the Alexa App...">
            <input matInput type="text" disabled [(ngModel)]="device.displayCategoryFriendlyName">
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <div class="dropdown">
            <button class="btn dropdown-toggle btn-sm" data-toggle="dropdown" aria-expanded="true" >
              <b class="caret"></b>
            </button>
            <ul class="dropdown-menu">
              <li (click)="setDisplayCategory('ACTIVITY_TRIGGER')"><a>Activity Trigger</a></li>
              <li (click)="setDisplayCategory('CAMERA')"><a>Camera</a></li>
              <li (click)="setDisplayCategory('DOOR')"><a>Door</a></li>
              <li (click)="setDisplayCategory('LIGHT')"><a>Light</a></li>
              <li (click)="setDisplayCategory('MICROWAVE')"><a>Microwave</a></li>
              <li (click)="setDisplayCategory('OTHER')"><a>Other</a></li>
              <li (click)="setDisplayCategory('SCENE_TRIGGER')"><a>Scene Trigger</a></li>
              <li (click)="setDisplayCategory('SMARTLOCK')"><a>Smart Lock</a></li>
              <li (click)="setDisplayCategory('SMARTPLUG')"><a>Smart Plug</a></li>
              <li (click)="setDisplayCategory('SPEAKER')"><a>Speaker</a></li>
              <li (click)="setDisplayCategory('SWITCH')"><a>Switch</a></li>
              <li (click)="setDisplayCategory('TEMPERATURE_SENSOR')"><a>Temperature Sensor</a></li>
              <li (click)="setDisplayCategory('THERMOSTAT')"><a>Thermostat</a></li>
              <li (click)="setDisplayCategory('TV')"><a>TV</a></li>
            </ul>
          </div>                    
        </div>   
      </div> 

      <div class="row" *ngIf="device.displayCategoryFriendlyName">
        <label class="col-sm-4 col-form-label">Type</label>
        <div class="col-sm-6">
          <mat-form-field class="example-full-width"  placeholder="Select a category for the Alexa App...">
            <input matInput type="text" disabled [(ngModel)]="device.typeFriendlyName">
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <div class="dropdown">
            <button class="btn dropdown-toggle btn-sm" data-toggle="dropdown" aria-expanded="true" >
              <b class="caret"></b>
            </button>
            <ul class="dropdown-menu">
              <li (click)="changeType('light')"><a>Light</a></li>
              <li (click)="changeType('thermostat')"><a>Thermostat</a></li>
              <li (click)="changeType('scene')"><a>Scene</a></li>
              <li (click)="changeType('entertainment')"><a>Entertainment</a></li>
            </ul>
          </div>                    
        </div>   
      </div>  

      <div class="row" *ngIf="device.displayCategoryFriendlyName && device.type">
        <label class="col-sm-4 col-form-label">Service</label>
        <div class="col-sm-6">
          <mat-form-field class="example-full-width"  placeholder="Select a service to integrate with Alexa...">
            <input matInput type="text" disabled [(ngModel)]="service.friendlyName">
          </mat-form-field>
        </div>
        <div class="col-sm-2">
          <div class="dropdown">
            <button class="btn dropdown-toggle btn-sm" data-toggle="dropdown" aria-expanded="true" >
              <b class="caret"></b>
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let serviceItem of services" (click)="changeService(serviceItem._id)"><a>{{ serviceItem.friendlyName }}</a></li>
            </ul>
          </div>                    
        </div>   
      </div> 
    </div> 
  </div>


  <div class="card" *ngIf="!device.displayCategoryFriendlyName">
    <div class="card-header row">
      <h4 class="card-title">Select a display category to get started...</h4>
    </div>
    <p class="category">The Alexa App shows the device in the selected category.</p>
  </div>

    <div class="card" *ngIf="!device.type && device.displayCategoryFriendlyName">
    <div class="card-header row">
      <h4 class="card-title">Select a type to continue...</h4>
    </div>
    <p class="category">The type determines what actions the device can perform.</p>
  </div>

  <div class="card" *ngIf="!service.friendlyName && device.displayCategoryFriendlyName && device.type">
    <div class="card-header row">
        <h4 class="card-title">Now select a service to integrate with Alexa...</h4>
        <p class="category"></p>
    </div>
  </div>


  <div *ngIf="service.friendlyName && device.type && device.displayCategoryFriendlyName">
    <div class="card" *ngFor="let type of device.types; let typeIndex = index">
      <div class="card-header row">
        <h4 class="card-title">{{ device.friendlyName }} {{ type.friendlyName }}</h4>
        <p class="category"></p>
      </div>

      <div>          
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Action</th>
              <th>Command</th>
              <th></th>
              <th>Response</th>
              <th></th>
            </tr>
          </thead>
          <tbody *ngFor="let action of type.actions; let actionIndex = index">
            <tr>
              <td>{{ action.friendlyName }}</td>
              <td>{{ getCommandFriendlyName(action.command) }}</td>
              <div class="dropdown">
                <button class="btn dropdown-toggle btn-sm" data-toggle="dropdown" aria-expanded="true" >
                  <b class="caret"></b>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let command of commands" (click)="setControllerCommand(command._id, typeIndex, actionIndex)"><a>{{ command.friendlyName }}</a></li>
                </ul>
              </div>    
              <td>{{ getResponseFriendlyName(action.response) }}</td>
              <div class="dropdown">
                <button class="btn dropdown-toggle btn-sm" data-toggle="dropdown" aria-expanded="true" >
                  <b class="caret"></b>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let response of responses" (click)="setControllerResponse(response._id, typeIndex, actionIndex)"><a>{{ response.friendlyName }}</a></li>
                </ul>
              </div>   
            </tr>
          </tbody>
        </table>  
      </div>   
    </div>
  </div>

</mat-dialog-content>
