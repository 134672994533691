<mat-dialog-content>
  <!-- <div class="card"> -->

    <div class="card-header row">
      <div class="loader" *ngIf=saving>
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
        </svg>
      </div>

      <div class="col-md-12">
        <h4 class="card-title">Register New Client</h4>
        <p class="category">Add a descriptive name for the client</p>
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <label class="col-md-4 col-form-label">Client Name</label>
        <div class="col-md-8">
          <mat-form-field class="example-full-width" placeholder="Enter a new name...">
            <input matInput type="text" required="true" [(ngModel)]="clientName">
          </mat-form-field>
        </div>
      </div>

      <div class="row pull-right">
        <div class="col-md-6">
          <button mat-raised-button class="btn btn-success btn-round" (click)="save()"><i
              class="material-icons">save_alt</i>
            Save</button>
        </div>
        <div class="col-md-6">
          <button mat-raised-button class="btn btn-default btn-round" (click)="cancel()"><i
              class="material-icons">cancel</i>
            Cancel</button>
        </div>
      </div>

      <div class="row pull-right">
        <div class="col-md-12 form-check">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" [(ngModel)]="privacyPolicyAccepted">
            <span class="form-check-sign">
              <span class="check"></span>
            </span>
            I have read and agree to the <a href="https://www.catchtechnologies.com/data-security/"
              target="_blank">Privacy Policy</a>    
          </label>
        </div>
      </div>

    </div>
  <!-- </div> -->

</mat-dialog-content>