import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { UserService } from './../user.service';
import { Router } from '@angular/router';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export var ROUTES: RouteInfo[] = [{
    path: '/clients',
    title: 'Clients',
    type: 'link',
    icontype: 'router',
}
// , {
//     path: '/services',
//     title: 'Services',
//     type: 'link',
//     icontype: 'compare_arrows',
// }
, {
    path: '',
    title: 'Integrations',
    type: 'sub',
    icontype: 'apps',
    collapse: 'collapse_integrations',
    children: [
        { path: 'alexa-smart-home', title: 'Alexa Smart Home', ab: 'A' },
        // { path: 'connectwise', title: 'ConnectWise', ab: 'C' },
        // { path: 'opsgenie', title: 'Opsgenie', ab: 'O' },        
        // { path: 'slack', title: 'Slack', ab: 'S' },        
        // { path: 'systems', title: 'Control Envy', ab: 'CE' },
        // { path: 'ifttt', title: 'IFTTT', ab: 'I' },
        // { path: 'bluejeans', title: 'Blue Jeans', ab: 'B' },        
        { path: 'users', title: 'Integration Users', ab: 'U' },        
    ]
}
// , {
//     path: '/content-projects',
//     title: 'Content',
//     type: 'link',
//     icontype: 'tv'
// }
// , {
//     path: '',
//     title: 'Notifications',
//     type: 'sub',
//     icontype: 'notifications_active',
//     collapse: 'collapse_notifications',
//     children: [
//         { path: 'email-accounts', title: 'Email Accounts', ab: 'A' },
//         { path: 'emails', title: 'Emails', ab: 'E' },        
//     ]
// }
// , {
//     path: '/automation',
//     title: 'Automation',
//     type: 'link',
//     icontype: 'settings_remote',
// }
// , {
//     path: '/data',
//     title: 'Charts',
//     type: 'link',
//     icontype: 'timeline'
// }
// , {
//     path: '/status-pages',
//     title: 'Status Pages',
//     type: 'link',
//     icontype: 'dns'
// }
];

@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public userName = "";

    constructor(
        private router: Router,
        public userService: UserService,
    ) {
    }

    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.userName = this.userService.user.username;
        if(this.userName.length > 23){
            this.userName = this.userName.substring(0, 20) + '...'
        }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            let ps = new PerfectScrollbar(elemSidebar, { wheelSpeed: 2, suppressScrollX: true });
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    account() {
        this.router.navigate(['account']);
    }

    async logout() {
        await this.userService.logout();
        this.router.navigate(['pages/login']);
    }

    async lock() {
        await this.userService.logout();
        this.router.navigate(['pages/lock']);
    }
}
