import { Component, OnInit, Inject } from '@angular/core';
import { ServicesService } from '../services.service';
import { Command } from '../models/command'
import { ServicesComponent } from '../services/services.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
  selector: 'app-edit-service-command',
  templateUrl: './edit-service-command.component.html',
  styleUrls: ['./edit-service-command.component.scss']
})
export class EditServiceCommandComponent implements OnInit {
  connection: any;
  command: Command;
  isNew = false;
  format = "string";

  constructor(private servicesService: ServicesService,
    public dialogRef: MatDialogRef<ServicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.connection = this.data.connection;    
    if (this.data.command) {
      this.command = this.data.command;
      if (this.command.useHex) {
        this.format = "hex";
      } else {
        this.format = "string";
      }
      if (!this.command.tags) {
        this.command.tags = [''];
        this.command.tags.splice(0, 1)
      }
    }
    this.isNew = this.data.isNew;
  }

  setFormatToString() {
    this.command.useHex = false;
  }

  setFormatToHex() {
    this.command.useHex = true;
  }

  save() {
    this.updateTags();
    if (this.command.friendlyName != "" && this.command.pattern != "") {
      if (this.isNew) {
        this.servicesService.addCommandWithConnectionId(this.command, this.connection._id).then((newCommand) => {
          this.dialogRef.close(newCommand);
        });
      } else {
        this.servicesService.saveCommandWithConnectionId(this.command, this.connection._id).then((newCommand) => {
          this.dialogRef.close(newCommand);
        });
      }
    } else {
      alert("Please include a name and a command...");
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    this.servicesService.deleteCommand(this.command).then(() => {
      this.dialogRef.close();
    });
  }

  updateTags() {
    let newArray: [string] = [''];
    newArray.splice(0, 1);
    for (var i = 0; i < this.command.tags.length; i++) {
      var tag = this.command.tags[i];
      if (typeof (tag) === 'object') {
        newArray.push(tag['value'] as string);
      } else if (typeof (tag) === 'string') {
        newArray.push(tag);
      }
    }
    this.command.tags = newArray;
  }
}
