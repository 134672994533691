import { Component, OnInit, Inject } from '@angular/core';
import { ClientsComponent } from '../clients/clients.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ClientService } from '../client.service';

declare var Stripe: any;

@Component({
  selector: 'app-select-plan',
  templateUrl: './select-plan.component.html',
  styleUrls: ['./select-plan.component.scss']
})
export class SelectPlanComponent implements OnInit {
  client;
  clientIsManaged = false;
  loading = false;
  annual = true;
  monthlyPlans = [
    {
      name: 'Hobby',
      description: '$5 per month',
      amount: 500,
      id: 'plan_FD9nOfnZ4ncE0X',
      maximumServices: 2,
      dataRetentionDays: 7
    }, 
    {
      name: 'DIY',
      description: '$19 per month',
      amount: 900,
      id: 'plan_FD9qRyQRymYkhN',
      maximumServices: 10,
      dataRetentionDays: 30
    },     
    {
      name: 'Room Manager',
      description: '$49 per month',
      amount: 4900,
      id: 'plan_EqBx9l2YlcPuHD',
      maximumServices: 25,
      dataRetentionDays: 90
    },
    {
      name: 'System Manager',
      description: '$199 per month',
      amount: 19900,
      id: 'plan_EqBxCR6bbR7YDC',
      maximumServices: 999,
      dataRetentionDays: 20000
    },
  ];
  annualPlans = [
    {
      name: 'Hobby',
      description: '$49 per year',
      amount: 4900,
      id: 'plan_FD9pY94Dc87td3',
      maximumServices: 2,
      dataRetentionDays: 7
    },
    {
      name: 'DIY',
      description: '$199 per year',
      amount: 9900,
      id: 'plan_FD9qxYMBw6zVrg',
      maximumServices: 10,
      dataRetentionDays: 30
    },
    {
      name: 'Room Manager',
      description: '$499 per year',
      amount: 49900,
      id: 'plan_EqBxkw6D9ii0pn',
      maximumServices: 25,
      dataRetentionDays: 90
    },
    {
      name: 'System Manager',
      description: '$1999 per year',
      amount: 199900,
      id: 'plan_EqBywaOT1AQJK5',
      maximumServices: 999,
      dataRetentionDays: 20000
    },
  ];
  pricing = this.annualPlans;
  
  // stripe = Stripe('pk_test_hagekW3up5CVNVBDIpMybHVj', {
  //   betas: ['checkout_beta_4']
  // });
  stripe = Stripe('pk_live_NYWBQt366h7J5ewsX8LCiFIO', {
    betas: ['checkout_beta_4']
  });

  constructor(private clientService: ClientService,
    public dialogRef: MatDialogRef<ClientsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  ngOnInit() {
    if (this.data.client) {
      this.client = this.data.client;
      if(this.client.balenaDeviceType.length > 0){
        this.clientIsManaged = true;
      }      
    } else {
      this.dialogRef.close(null);
    }
  }

  billingCycleChanged(event) {
    this.annual = event.target.checked;
    this.adjustPricing();
  }

  adjustPricing() {
    if (this.annual) {
      this.pricing = this.annualPlans;
    } else {
      this.pricing = this.monthlyPlans;
    }
  }

  openCheckout(plan) {
    const clientReferenceId = this.client.uuid + ';' + plan.name  + ';' + plan.maximumServices  + ';' + plan.dataRetentionDays;
    this.stripe.redirectToCheckout({
      items: [{ plan: plan.id, quantity: 1 }],
      clientReferenceId: clientReferenceId,
      successUrl: 'https://connect.catchtechnologies.com/clients',
      cancelUrl: 'https://connect.catchtechnologies.com/clients'
    }).then((result) => {
      if (result.error) {
        console.log('Error subscribing to plan.')
        console.log(result.error.message)
      }
    });
  }
}
