import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router) {
  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.userService.isAuthenticated()) {
      return true;
    }

    try {
      await this.userService.me();
    } catch (e) {
      console.log('error logging in: ' + e);
    }

    if (!this.userService.isAuthenticated()) {
      const params = next.queryParams;
      if (params) {
        if (params.id) {
          this.router.navigate(['pages/login'], { queryParams: { id: params.id, redirect: 'clients' } });
        } else if (params.code && params.state) {
          this.router.navigate(['slack'], { queryParams: params });
        }
        else {
          this.router.navigate(['pages/login']);
        }
      } else {
        this.router.navigate(['pages/login']);
      }
      return false;
    } else {
      const params = next.queryParams;
      if (params) {
        if (params.id) {
          this.router.navigate(['clients'], { queryParams: { id: params.id, redirect: 'clients' } });
        } else if (params.registrationCode) {
          this.router.navigate(['pages/login'], { queryParams: params });
        } else if (params.resetPasswordCode) {
          this.router.navigate(['pages/reset-password'], { queryParams: params });
        } else if (params.code && params.state) {
          this.router.navigate(['slack'], { queryParams: params });
        }
      } else {
        this.router.navigate(['clients']);
      }
      return true;
    }
  }
}