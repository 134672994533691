import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  config

  setConfig(config) {
    this.config = config;
  }

  isConfigured() {
    return this.config &&
      this.config.token;
  }

  getToken() {
    return this.config && this.config.token;
  }
}
