<div class="logo">
    <a class="simple-text logo-mini">
        <div class="logo-img">
            <img src="/assets/img/CatchIcon.png" />
        </div>
    </a>
    <a class="simple-text logo-normal">
        Catch Connect
    </a>
</div>


<div class="sidebar-wrapper">

    <div class="user">
        <div class="photo">
            <img src="./assets/img/default-avatar.png" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseExample" class="collapsed">
                <span>
                    {{userName}}
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseExample">
                <ul class="nav">
                    <li class="nav-item">
                        <a href="javascript:void(0)" (click)="account()" class="nav-link">
                            <span class="sidebar-mini"></span>
                            <span class="sidebar-normal">
                                <i class="material-icons">account_circle</i>
                                <a>Account</a>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini"></span>
                            <span class="sidebar-normal">
                                <i class="material-icons">cancel</i>
                                <a (click)="logout()">Logout</a>
                            </span>
                        </a>
                    </li>
                    <!--li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link">
                            <span class="sidebar-mini"></span>                                                                          
                            <span class="sidebar-normal">
                                <i class="material-icons">lock</i>
                                <a (click)="lock()">Lock</a>
                            </span>
                        </a>
                    </li-->
                </ul>
            </div>
        </div>
    </div>
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
            <!--If is a single link-->
            <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title}}</p>
            </a>
            <!--If it has a submenu-->
            <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
                class="nav-link">
                <i class="material-icons">{{menuitem.icontype}}</i>
                <p>{{menuitem.title}}<b class="caret"></b></p>
            </a>

            <!--Display the submenu items-->
            <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
                        <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
                            <span class="sidebar-mini">{{childitem.ab}}</span>
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </li>
    </ul>
</div>