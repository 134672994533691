import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  projects;
  selectedProject;
  images;

  constructor(private http: HttpClient,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  async getProjects() {
    const result = await this.http.get(`${this.backendConfig.url}api/content`).toPromise();
    this.projects = <any>result;
    return this.projects;
  }

  async getProject(id: string) {
    this.selectedProject = this.http.get(`${this.backendConfig.url}api/content/${id}`).toPromise();
    return this.selectedProject;
  }

  async addNewProject(name: string) {
    const client = await this.http.post(this.backendConfig.url + 'api/newContent/', {
      name: name
    }).toPromise();
    return await this.getProjects();
  }

  async updateProject(project) {
    this.selectedProject = this.http.post(`${this.backendConfig.url}api/content/`, project).toPromise();
    return this.selectedProject;
  }

  async deleteProject(id: string) {
    const client = await this.http.post(this.backendConfig.url + 'api/deleteContent/', {
      id: id
    }).toPromise();
    return await this.getProjects();
  }

  async addNewImageText(text: string, project: string) {
    const client = await this.http.post(this.backendConfig.url + 'api/image-text/', {
      name: text,
      location: text,
      project: project
    }).toPromise();
    return await this.getProjects();
  }

  async getImages() {
    const result = await this.http.get(`${this.backendConfig.url}api/image`).toPromise();
    const results = <any>result;
    this.images = [];
    for (var i = 0; i < results.length; i++) {
      const location = results[i].location;
      const name = results[i].name;
      const image = {
        src: location,
        name: name
      };
      this.images.push(image);
    }
    return this.images;
  }

  async publishContent(contentId) {
    const body = { contentId: contentId };
    return this.http.post<any>(`${this.backendConfig.url}api/publish-content/`, body).toPromise();
  }

}
